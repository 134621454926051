import { type KeyPrefix, type Namespace } from 'i18next'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { type _DefaultNamespace } from 'react-i18next/TransWithoutContext'

export function useT<
	N extends Namespace = _DefaultNamespace,
	TKPrefix extends KeyPrefix<N> = undefined,
>(ns?: string, keyPrefix?: TKPrefix) {
	return useTranslation(ns, { keyPrefix }).t
}

export function useLocale() {
	return useTranslation().i18n.language
}

export function useDirection() {
	return useTranslation().i18n.dir()
}

export function useChangeLanguage(locale?: string) {
	let { i18n } = useTranslation()
	useEffect(() => {
		i18n.changeLanguage(locale)
	}, [locale, i18n])
	const changeLanguage = (locale: string) => i18n.changeLanguage(locale)
	return [i18n.language, changeLanguage]
}
